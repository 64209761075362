<template>
  <div id="About" v-if="page_data != null">
    <div class="about_scroll_image">
      <div class="logo_box">
        <p class="logo">
          {{ page_data[0].title.split(',')[0] }}
        </p>
        <span>{{ page_data[0].title.split(',')[1] }}</span>
      </div>

      <img class="bg" :src="page_data[0].image" />
    </div>

    <section class="about_company">
      <img
        data-aos="fade-up"
        data-aos-delay="400"
        src="/img/logo_b.png"
        class="logo"
      />
      <div
        class="content"
        data-aos="fade-up"
        data-aos-delay="500"
        v-html="page_data[0].content"
      ></div>
    </section>

    <section class="about_middle_part">
      <img src="/img/logo_b.png" class="logo" data-aos="animation" />
      <div
        v-for="line in 20"
        :key="`text_content_line_${line}`"
        class="content"
        data-aos="animation"
        data-aos-delay="500"
      >
        <div class="text_line" :class="{ even: line % 2 == 0 }">
          <p v-for="item in 10" :key="`text_line_${item}`">SIFUTON</p>
        </div>
      </div>
      <div class="bg_cover" data-aos="animation"></div>
    </section>

    <section class="company_info">
      <div class="row">
        <div class="header" data-aos="fade-up" data-aos-delay="200">
          <div>
            <h4>
              <span>INFO</span>
            </h4>
            <h3>公司資訊</h3>
          </div>
        </div>
        <div class="content">
          <ol>
            <li data-aos="fade-up" data-aos-delay="100">
              <p class="title"><span>公司名稱</span></p>
              <p class="text_content">
                {{ GetFormData('company_name').title }}
              </p>
            </li>
            <li data-aos="fade-up" data-aos-delay="200">
              <p class="title"><span>設立日期</span></p>
              <p class="text_content">
                {{ GetFormData('company_create_date').title }}
              </p>
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              <p class="title"><span>公司登記地址</span></p>
              <p class="text_content">
                {{ GetFormData('company_address').title }}
              </p>
            </li>
            <li data-aos="fade-up" data-aos-delay="400">
              <p class="title"><span>聯絡電話</span></p>
              <p class="text_content">
                {{ GetFormData('company_phone').title }}
              </p>
            </li>
          </ol>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import qs from 'qs';
export default {
  name: 'About',
  data() {
    return {
      page_data: null,
      meta_content: '',
    };
  },
  methods: {
    async GetAboutData() {
      let result = await this.SendPostData(
        process.env.VUE_APP_BASE_API + 'web_edit/get_common_column.php',
        qs.stringify({
          column_array: [
            'about',
            'company_name',
            'company_address',
            'company_phone',
            'company_email',
            'company_time',
            'company_create_date',
          ],
        })
      );
      if (result != 'error') {
        this.page_data = JSON.parse(result.data);
        this.meta_content = this.page_data[0].content.replace(/<[^>]+>/g, '');
        this.$nextTick(() => {
          window.prerenderReady = true;
        });
      }
    },
    GetFormData(key) {
      return this.page_data.filter((item) => item.column_name == key)[0];
    },
  },
  created() {
    this.GetAboutData();
  },

  metaInfo() {
    return {
      title: '關於我們｜欣富通｜SIFUTON',
      meta: [
        {
          property: 'og:description',
          content:
            '欣富通成立於1993年，專注於生產機械停車和油壓電梯高品質的昇降系統，用專業知識及技術創新來服務國內產業界，一直為客戶提供安全可靠和耐用持久的產品，隨著時間的累積，我們對客戶的需求持續關注使我們在各種應用積累了經驗，增加了我們的產品組合，並成為昇降系統應用的領先製造商。',
          vmid: 'og:description',
        },
        {
          property: 'twitter:description',
          content:
            '欣富通成立於1993年，專注於生產機械停車和油壓電梯高品質的昇降系統，用專業知識及技術創新來服務國內產業界，一直為客戶提供安全可靠和耐用持久的產品，隨著時間的累積，我們對客戶的需求持續關注使我們在各種應用積累了經驗，增加了我們的產品組合，並成為昇降系統應用的領先製造商。',
          vmid: 'twitter:description',
        },
        {
          property: 'description',
          content:
            '欣富通成立於1993年，專注於生產機械停車和油壓電梯高品質的昇降系統，用專業知識及技術創新來服務國內產業界，一直為客戶提供安全可靠和耐用持久的產品，隨著時間的累積，我們對客戶的需求持續關注使我們在各種應用積累了經驗，增加了我們的產品組合，並成為昇降系統應用的領先製造商。',
          vmid: 'description',
        },
        {
          property: 'og:image',
          content: 'https://www.sifuton.com.tw/img/share/index.jpg',
          vmid: 'og:image',
        },
        {
          property: 'apple-mobile-web-app-title',
          content: '關於我們｜欣富通｜SIFUTON',
          vmid: 'apple-mobile-web-app-title',
        },
        {
          property: 'application-name',
          content: '關於我們｜欣富通｜SIFUTON',
          vmid: 'application-name',
        },
        {
          property: 'og:site_name',
          content: '關於我們｜欣富通｜SIFUTON',
          vmid: 'og:site_name',
        },
        {
          property: 'og:type',
          content: 'website',
          vmid: 'og:type',
        },
        {
          property: 'og:locale',
          content: 'zh_tw',
          vmid: 'og:locale',
        },
      ],
    };
  },
};
</script>
